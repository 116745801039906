import {sliderWidthMigration} from '../migration-scripts/sliderMigration';
import {adiMissingPagesMigration} from '../migration-scripts/adiMissingPagesMigration';
import {reinstallPage} from '../migration-scripts/reinstallPage';
import {wishlistPageId} from '../constants';
import {freeProductsLegacyFlag} from '../migration-scripts/freeProductsLegacyFlag';
import {productWithSubscriptionSaved} from '../migration-scripts/productWithSubscriptionSaved';

export function handleAction({type, payload}, sdk, storeId, dependantApps, biLogger) {
  // eslint-disable-next-line @typescript-eslint/tslint/config
  try {
    if (type === 'migrate') {
      switch (payload.type) {
        case 'sliderWidth':
          return sliderWidthMigration(sdk);
        case 'adiMissingPages':
          return adiMissingPagesMigration(sdk, storeId, biLogger);
        case 'reinstallWishlist':
          return reinstallPage(sdk, wishlistPageId, dependantApps.installWishlistPageInMembersArea.bind(dependantApps));
        case 'reinstallCheckout':
          return reinstallPage(sdk, 'checkout', dependantApps.addCheckoutIfNeeded.bind(dependantApps));
        case 'tryInstallWishlistTPA':
          return dependantApps.installWishlistTPA();
        case 'freeProductsLegacyFlag':
          return freeProductsLegacyFlag(sdk, payload.value);
        case 'productWithSubscriptionSaved':
          return productWithSubscriptionSaved();
        default:
          return Promise.resolve();
      }
    } else {
      return Promise.resolve();
    }
  } catch (e) {
    return Promise.reject(e);
  }
}
